import React from "react";

export default function KenyaFlagIcon() {
  return (
    <div>
      <svg
        width="55"
        height="18"
        viewBox="0 0 71 28"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g filter="url(#filter0_dd_6654_10538)">
          <g clip-path="url(#clip0_6654_10538)">
            <rect
              x="2"
              y="1"
              width="33"
              height="23.5714"
              rx="2.5656"
              fill="#151515"
            />
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M2 18.2855H35V24.5712H2V18.2855Z"
              fill="#0A6A30"
            />
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M2 16.7141H35V18.2855H2V16.7141Z"
              fill="white"
            />
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M2 8.85698H35V16.7141H2V8.85698Z"
              fill="#F93939"
            />
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M2 7.28551H35V8.85698H2V7.28551Z"
              fill="white"
            />
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M21.5079 10.2932C21.621 10.2932 22.2936 9.24039 22.2936 7.93611C22.2936 6.63496 21.621 5.57896 21.5079 5.57896C21.3979 5.57896 20.7222 6.63496 20.7222 7.93611C20.7222 9.24039 21.3979 10.2932 21.5079 10.2932ZM16.8894 9.60339C16.8109 9.68196 15.5852 9.41482 14.6674 8.49239C13.7482 7.57154 13.4779 6.34896 13.558 6.27039C13.6366 6.19182 14.8592 6.45896 15.78 7.38139C16.7009 8.30225 16.9696 9.52482 16.891 9.60496L16.8894 9.60339ZM19.8422 16.7267C19.9207 16.6528 21.1464 16.9043 22.0657 17.7638C22.985 18.6281 23.2553 19.7705 23.1767 19.8444C23.0982 19.9182 21.8724 19.6668 20.9516 18.8072C20.0323 17.943 19.762 16.8005 19.8422 16.7267ZM16.8894 16.7267C16.8109 16.6528 15.5852 16.9043 14.6674 17.7638C13.7482 18.6281 13.4779 19.7705 13.558 19.8444C13.6366 19.9182 14.8592 19.6668 15.78 18.8072C16.7009 17.943 16.9696 16.8005 16.891 16.7267H16.8894Z"
              fill="white"
            />
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M18.5 19.857C19.061 19.857 22.4286 16.6906 22.4286 12.7856C22.4286 8.88061 19.061 5.71418 18.5 5.71418C17.939 5.71418 14.5714 8.88061 14.5714 12.7856C14.5714 16.6906 17.939 19.857 18.5 19.857Z"
              fill="#F93939"
            />
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M15.3572 15.1427C15.4672 15.1427 16.1429 14.0867 16.1429 12.7856C16.1429 11.4845 15.4672 10.4285 15.3572 10.4285C15.2472 10.4285 14.5714 11.4845 14.5714 12.7856C14.5714 14.0868 15.2472 15.1427 15.3572 15.1427ZM21.6429 15.1427C21.7529 15.1427 22.4286 14.0868 22.4286 12.7856C22.4286 11.4845 21.7529 10.4285 21.6429 10.4285C21.5329 10.4285 20.8572 11.4845 20.8572 12.7856C20.8572 14.0867 21.5329 15.1427 21.6429 15.1427Z"
              fill="#151515"
            />
            <path
              d="M19.2857 12.7856C19.2857 11.4838 18.9339 10.4285 18.5 10.4285C18.066 10.4285 17.7142 11.4838 17.7142 12.7856C17.7142 14.0874 18.066 15.1427 18.5 15.1427C18.9339 15.1427 19.2857 14.0874 19.2857 12.7856Z"
              fill="white"
            />
          </g>
        </g>
        <path
          d="M65.4365 15.7458C65.2062 16.0117 64.7938 16.0117 64.5636 15.7459L59.8274 10.2769C59.5036 9.90302 59.7692 9.32163 60.2638 9.32163L69.7362 9.32163C70.2309 9.32163 70.4965 9.90302 70.1727 10.2769L65.4365 15.7458Z"
          fill="#A7A5A5"
        />
        <defs>
          <filter
            id="filter0_dd_6654_10538"
            x="0.0758017"
            y="0.3586"
            width="36.8484"
            height="27.4198"
            filterUnits="userSpaceOnUse"
            color-interpolation-filters="sRGB"
          >
            <feFlood flood-opacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
              result="hardAlpha"
            />
            <feOffset dy="1.2828" />
            <feGaussianBlur stdDeviation="0.962099" />
            <feComposite in2="hardAlpha" operator="out" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0.258824 0 0 0 0 0.278431 0 0 0 0 0.298039 0 0 0 0.08 0"
            />
            <feBlend
              mode="normal"
              in2="BackgroundImageFix"
              result="effect1_dropShadow_6654_10538"
            />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
              result="hardAlpha"
            />
            <feOffset />
            <feGaussianBlur stdDeviation="0.0801749" />
            <feComposite in2="hardAlpha" operator="out" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0.258824 0 0 0 0 0.278431 0 0 0 0 0.298039 0 0 0 0.32 0"
            />
            <feBlend
              mode="normal"
              in2="effect1_dropShadow_6654_10538"
              result="effect2_dropShadow_6654_10538"
            />
            <feBlend
              mode="normal"
              in="SourceGraphic"
              in2="effect2_dropShadow_6654_10538"
              result="shape"
            />
          </filter>
          <clipPath id="clip0_6654_10538">
            <rect
              x="2"
              y="1"
              width="33"
              height="23.5714"
              rx="2.5656"
              fill="white"
            />
          </clipPath>
        </defs>
      </svg>
    </div>
  );
}
