import React from "react";

export default function GhanaFlagIcon() {
  return (
    <div>
      <svg
        width="49"
        height="16"
        viewBox="0 0 70 22"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g clip-path="url(#clip0_6654_10538)">
          <rect width="33.0748" height="22" rx="2.2449" fill="white" />
          <path d="M0 0H33.0748V22H0V0Z" fill="#006B3F" />
          <path d="M0 0H33.0748V14.6667H0V0Z" fill="#FCD116" />
          <path d="M0 0H33.0748V7.33333H0V0Z" fill="#CE1126" />
          <path
            d="M16.5374 7.40816L17.4278 10.1486H20.3093L17.9781 11.8423L18.8686 14.5827L16.5374 12.889L14.2063 14.5827L15.0967 11.8423L12.7655 10.1486H15.647L16.5374 7.40816Z"
            fill="#000008"
          />
        </g>
        <path
          d="M63.5112 13.9601C63.2809 14.226 62.8685 14.226 62.6383 13.9601L57.9021 8.49122C57.5783 8.1173 57.8439 7.53591 58.3385 7.53591L67.811 7.53591C68.3056 7.53591 68.5712 8.1173 68.2474 8.49122L63.5112 13.9601Z"
          fill="#A7A5A5"
        />
        <defs>
          <clipPath id="clip0_6654_10538">
            <rect width="33.0748" height="22" rx="2.2449" fill="white" />
          </clipPath>
        </defs>
      </svg>
    </div>
  );
}
