import React from "react";

export default function NigeriaFlagIcon() {
  return (
    <div>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="50"
        height="15"
        viewBox="0 0 69 24"
        fill="none"
      >
        <path
          d="M4.32682 0C3.35436 0 2.42173 0.386308 1.7341 1.07394C1.04646 1.76158 0.660156 2.69421 0.660156 3.66667V20.1667C0.660156 21.1391 1.04646 22.0718 1.7341 22.7594C2.42173 23.447 3.35436 23.8333 4.32682 23.8333H11.6602V0H4.32682Z"
          fill="#009A49"
        />
        <path d="M11.6602 0H22.6602V23.8333H11.6602V0Z" fill="#EEEEEE" />
        <path
          d="M29.9935 0H22.6602V23.8333H29.9935C30.966 23.8333 31.8986 23.447 32.5862 22.7594C33.2738 22.0718 33.6602 21.1391 33.6602 20.1667V3.66667C33.6602 2.69421 33.2738 1.76158 32.5862 1.07394C31.8986 0.386308 30.966 0 29.9935 0Z"
          fill="#009A49"
        />
        <path
          d="M64.0966 14.8768C63.8663 15.1426 63.4539 15.1427 63.2237 14.8768L58.4875 9.40789C58.1637 9.03398 58.4293 8.45258 58.9239 8.45258L68.3963 8.45258C68.891 8.45258 69.1566 9.03398 68.8328 9.40789L64.0966 14.8768Z"
          fill="#A7A5A5"
        />
      </svg>
    </div>
  );
}
