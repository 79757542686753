import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import HomePage from "../src/component/NGN/homepage/HomePage";
import KenyaHome from "../src/component/KEN/KenyaHome";
import GhanaHome from "../src/component/GHN/GhanaHome";
import Notfound from "./component/NGN/pagenotfound/Notfound";



import { useEffect } from "react";
import {  useLocation } from "react-router-dom";
import ContactUs from "./component/NGN/contactus/ContactUs";
import KenyaContactUs from "./component/KEN/contactus/KenyaContactUs";

function ScrollToTop() {
  const { pathname } = useLocation();

  useEffect(() => {
   

      window.scrollTo(0, 0);
   
  }, [pathname]);

  return null;
}





const RootApplication = () => {
  return (
    <>
    
 
      <Routes>
        
        <Route path="/" exact element={<HomePage />} />
        <Route path="/contactus"  element={<ContactUs />} />
        <Route path="/kenyacontactus"  element={<KenyaContactUs />} />
        <Route path="/ghanacontactus"  element={<KenyaContactUs />} />
        <Route path="*" element={<Notfound />} />
        <Route path="/ken" exact element={<KenyaHome />} />
        <Route path="/ghn" exact element={<GhanaHome />} />
        
      </Routes>
     
      
    </>
  );
};

function App() {
  return (
    <Router>
          <ScrollToTop />
           <RootApplication />
        
     
    </Router>
  );
}

export default App;
