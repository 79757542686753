import React from 'react'
import { Container, Image } from 'react-bootstrap';
import logo from '../../assets/logo.svg';
import '../GHN/herosection.css';

export default function HeroSection() {
  return (
    <Container fluid className='section'>
        <Container>
        <Image width='220' src={logo} fluid alt="image" />
        <h1 className='heroheader' style={{ color:'#F14810'}}><strong>Coming Soon...</strong></h1>
        <p className="herotext" style={{fontSize:0.8+'rem'}}>Adept is an evolving digital payment solution for fast, automated payments, targeting billing agencies, schools, hospitals, and more.</p>
        </Container>
      
      
    </Container>
  )
}
