import React from 'react'
import StickyNavbar from './Navbar/StickyNavbar'
import HeroSection from './HeroSection'

export default function KenyaHome() {
  return (
    <div>
        <StickyNavbar />
        <HeroSection />
      
    </div>
  )
}
