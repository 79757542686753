import React, { useState } from "react";
import {
 
  Image,
 
} from "react-bootstrap";

import { easeOut, motion } from "framer-motion";
import {  Link } from "react-router-dom";
import { Modal } from "react-bootstrap";
import logo from "../../../assets/logo.svg";
import Nigeriaflag from "../../../assets/Nigeriaflag.svg";
import kenyaflag from "../../../assets/kenyaflag.svg";
import ghanaflag from "../../../assets/ghanaflag.svg";
import noticeicon from "../../../assets/noticeicon.svg";
import "./navbar.css";
import GhanaFlagIcon from "./GhanaFlagIcon";



function SelectCountry() {
  
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);


 

 
  return (
           <>
            <div onClick={handleShow} className="selectlang">
              <GhanaFlagIcon/>
            </div>

            <Modal
              show={show}
              onHide={handleClose}
              className="countryModal"
              
            >
              <Modal.Header closeButton>
                <Modal.Title style={{ fontSize: 0.95 + "rem" }}>
                  <Image
                    src={logo}
                    style={{ width: 6 + "rem" }}
                    fluid
                    alt="image"
                  />
                </Modal.Title>
              </Modal.Header>
              <Modal.Body>

              <motion.div
                  initial={{ y: 15, opacity: 0 }}
                  animate={{ y: -5, opacity: 1 }}
                  transition={{
                    duration: 0.4,
                    delay: 0.2,
                    timingFunction: easeOut,
                  }}
                
                >
                <div 
                  className="noticecard"
                >
                  <div>
                    {" "}
                    <Image src={noticeicon} fluid alt="image" />
                  </div>
                  <div>
                    <p style={{ fontSize: 0.72 + "rem", fontFamily: "Jost" }}>
                      {" "}
                      You are on our Nigerian Page. To view content relevant to
                      your location, select a different country or region, or
                      continue here.
                    </p>
                  </div>
                </div>

                </motion.div>
                

                <motion.div
                  initial={{ y: 15, opacity: 0 }}
                  animate={{ y: -5, opacity: 1 }}
                  transition={{
                    duration: 0.4,
                    delay: 0.2,
                    timingFunction: easeOut,
                  }}
                
                >
                <div className="selectionarea">
                  <h6 style={{ fontSize: 0.7 + "rem", color: "#737373" }}>
                    SELECT REGION:
                  </h6>
                  <div style={{display:'flex',flexDirection:'column',gap:5,}}>

                  <Link to="/">
                    <div
                      className="countrySelection">
                      <div style={{display:'flex', flexDirection:'row',gap:0.6+'rem',}}>
                        <Image src={Nigeriaflag} fluid alt="image" />
                        <span style={{fontSize:0.8+'rem'}}>Nigeria</span>
                      </div>
                    </div>
                    </Link>
                    
                    <Link to="/ken">
                    <div
                      className="countrySelection">
                      <div style={{display:'flex', flexDirection:'row',gap:0.6+'rem',}}>
                        <Image src={kenyaflag} fluid alt="image" />
                        <span style={{fontSize:0.8+'rem'}}>Kenya <em style={{fontSize:0.70+'rem'}}>- Coming Soon</em></span>
                      </div>
                    </div>
                    </Link>
                    
                    <Link to="/ghn">
                    <div
                      className="countrySelection-active">
                      <div style={{display:'flex', flexDirection:'row',gap:0.6+'rem',}}>
                        <Image src={ghanaflag} fluid alt="image" />
                        <span style={{fontSize:0.8+'rem'}}>Ghana <em style={{fontSize:0.70+'rem'}}>- Coming Soon</em></span>
                      </div>
                    </div>
                    </Link>
                  </div>
                </div>
                </motion.div>
              </Modal.Body>
            </Modal>
            </>

          

            
  );
}

export default SelectCountry;
