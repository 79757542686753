import React from "react";
import { Container, Button, Row, Col, Form, InputGroup } from "react-bootstrap";
import { easeOut, motion } from "framer-motion";
import { Link } from "react-router-dom";
import "../../homepage/homepage.css";

import Avatarone from "../../../../assets/Avatarone.png";
import Avatartwo from "../../../../assets/Avatartwo.svg";
import Avatarthree from "../../../../assets/Avatarthree.png";

const CircularCard = ({ imageSrc }) => {
  return (
    <div className="circular-card">
      <div className="circular-image">
        <img src={imageSrc} alt="Card" />
      </div>
    </div>
  );
};

function Contactus() {
  const buttonstyle = {
    paddingTop: 0.6 + "rem",
    paddingBottom: 0.6 + "rem",
    paddingLeft: 1.1 + "rem",
    paddingRight: 1.1 + "rem",
    fontWeight: "400",
    backgroundColor: "#F14810",
  };

  return (
    <Container fluid id="contact">
      <motion.div
        initial={{ y: 20, opacity: 0 }}
        whileInView={{ y: -5, opacity: 1 }}
        transition={{ duration: 0.3, delay: 0.4, timingFunction: easeOut }}
        viewport={{ once: true }}
      >
        <Container fluid className="Contactus">
          <Row>
            <Col xs={12} sm={12} md={6} lg={6} className="contactsection">
              <div className="card-stack">
                <div className="middle-card">
                  <img src={Avatartwo} alt="Middle Card" />
                </div>
                <CircularCard imageSrc={Avatarone} alt="Avatarimage" />
                <CircularCard imageSrc={Avatarthree} alt="Avatarimage" />
              </div>
              <br />

              <div style={{ marginTop: 2.5 + "rem" }}>
                <h3
                  style={{
                    color: "#101828",
                    fontSize: 1.4 + "rem",
                    fontWeight: "500",
                  }}
                >
                  <strong>Contact Us</strong>
                </h3>
                <p style={{ color: "#667085", fontSize: 0.75 + "rem" }}>
                  Our dedicated team of frontline heroes are available round the clock to provide you with superior assistance,
                  ensuring your journey with us is hitch free.{" "}
                </p>
                <p style={{ color: "#101828", fontSize: 0.75 + "rem" }}>
                  Call: 02096244444<br/>
                  WhatsApp: 09030009930
                </p>
                <Link to="/contactus">
                  <Button
                    style={buttonstyle}
                    variant="primary"
                    className="button"
                    size="sm"
                  >
                    Get in touch
                  </Button>
                </Link>
              </div>
            </Col>
            <Col xs={12} sm={12} md={6} lg={6} className="requestcall">
              <div style={{ marginTop: 2 + "rem" }}>
                <h3
                  style={{
                    color: "#FF551D",
                    fontSize: 1.4 + "rem",
                    fontWeight: "500",
                  }}
                >
                  <strong>Request For a Call back</strong>
                </h3>
                <p style={{ color: "#E2E2E2", fontSize: 0.75 + "rem" }}>
                  Would you prefer a callback? We're here to assist you.
                  Please provide your details below, and our dedicated team will reach out to you at your convenience.{" "}
                </p>

                <InputGroup id="requestinput" className="mb-2">
                  
                  <Form.Control
                    id="inlineFormInputGroup"
                    placeholder="Name"
                  />
                </InputGroup>
                <InputGroup id="requestinput" className="mb-2">
                  <InputGroup.Text>
                    <svg
                      width="28"
                      height="21"
                      viewBox="0 0 33 24"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <g id="Group 465">
                        <path
                          id="Vector"
                          d="M3.66667 0C2.69421 0 1.76158 0.386308 1.07394 1.07394C0.386308 1.76157 0 2.69421 0 3.66667L0 20.1667C0 21.1391 0.386308 22.0718 1.07394 22.7594C1.76158 23.447 2.69421 23.8333 3.66667 23.8333H11V0H3.66667Z"
                          fill="#009A49"
                        />
                        <path
                          id="Vector_2"
                          d="M11 0H22V23.8333H11V0Z"
                          fill="#EEEEEE"
                        />
                        <path
                          id="Vector_3"
                          d="M29.3333 0H22V23.8333H29.3333C30.3058 23.8333 31.2384 23.447 31.9261 22.7594C32.6137 22.0718 33 21.1391 33 20.1667V3.66667C33 2.69421 32.6137 1.76157 31.9261 1.07394C31.2384 0.386308 30.3058 0 29.3333 0Z"
                          fill="#009A49"
                        />
                      </g>
                    </svg>
                  </InputGroup.Text>
                  <Form.Control
                    id="inlineFormInputGroup"
                    placeholder="Phone Number"
                  />
                </InputGroup>
                <InputGroup id="requestinput" className="mb-2">

                  <Form.Control
                    id="inlineFormInputGroup"
                    placeholder="Preferred time of call back"
                  />
                </InputGroup>
                <div style={{ marginBottom: 1.2 + "rem" }}></div>
              
                  <Button
                    style={buttonstyle}
                    variant="primary"
                    className="button"
                    size="sm"
                  >
                    Send Request
                  </Button>
             
              </div>
            </Col>
          </Row>
        </Container>
      </motion.div>
    </Container>
  );
}

export default Contactus;
