import React from "react";
import { Image, Row, Col, Container } from "react-bootstrap";
import { easeOut, motion } from "framer-motion";
import flowimage from "../../../../assets/flowimage.png";
import payment from "../../../../assets/payment.png";
import organisation from "../../../../assets/organisation.png";
import institution from "../../../../assets/institution.png";

function AboutAdept() {
  return (
    <Container fluid className="aboutadept">
      <Row>
        <Col
          className="order-md-2 aboutadeptright"
          xs={12}
          sm={12}
          md={6}
          lg={6}
        >
          <motion.div
            initial={{ y: 20, opacity: 0 }}
            whileInView={{ y: -5, opacity: 1 }}
            transition={{ duration: 0.3, delay: 0.4, timingFunction: easeOut }}
            viewport={{ once: true }}
          >
            <h2>
              <strong>What is Adept?</strong>
            </h2>
            <p>
              Adept is a payment solution that automates recurring payments, confirms and provides value at the speed of light.
              This solution offers a comprehensive suite of features designed to meet the demands of a dynamic and fast-paced digital payment environment.
              Adept is your partner in navigating the evolving landscape of payments with efficiency and precision.
            </p>
            <Row>
              <Col>
                <ul
                  style={{ fontSize: 0.85 + "rem", lineHeight: 205 + "%" }}
                  className="Featurelist"
                >
                  <li>User Friendly Interface.</li>
                  <li>60 seconds Turnaround Time.</li>
                  <li>Data driven Dashboard.</li>
                  <li>Efficient reporting tools.</li>
                  <li>Audit Trail.</li>
                  <li>Instant  settlements.</li>
                </ul>
              </Col>
              <Col>
                <ul
                  style={{ fontSize: 0.85 + "rem", lineHeight: 205 + "%" }}
                  className="Featurelist"
                >
                  
                  <li>Centralized Management and support.</li>
                  <li>Supports Multiple payment channels</li>
                  <li>Instant transaction fulfilment.</li>
                  <li>Eco-Friendly solution.</li>
                </ul>
              </Col>
            </Row>
          </motion.div>
        </Col>

        <Col className="order-md-1" xs={12} sm={12} md={6} lg={6}>
          <motion.div
            initial={{ y: 20, opacity: 0 }}
            whileInView={{ y: -5, opacity: 1 }}
            transition={{ duration: 0.3, delay: 0.4, timingFunction: easeOut }}
            viewport={{ once: true }}
          >
            <div className="aboutadeptimagecontainer">
              <motion.div
                initial={{ opacity: 0 }}
                whileInView={{ opacity: 1 }}
                transition={{
                  duration: 0.7,
                  delay: 0.9 + 0.4,
                  timingFunction: easeOut,
                }}
                viewport={{ once: true }}
              >
                <Image
                  src={institution}
                  width={210}
                  className="institutionimage"
                />
              </motion.div>
              <motion.div
                initial={{ opacity: 0 }}
                whileInView={{ opacity: 1 }}
                transition={{
                  duration: 0.7,
                  delay: 0.9 + 0.8,
                  timingFunction: easeOut,
                }}
                viewport={{ once: true }}
              >
                <Image
                  src={organisation}
                  width={210}
                  className="organisationimage"
                />
              </motion.div>
              <motion.div
                initial={{ opacity: 0 }}
                whileInView={{ opacity: 1 }}
                transition={{
                  duration: 0.7,
                  delay: 0.9 + 0.8 + 0.4,
                  timingFunction: easeOut,
                }}
                viewport={{ once: true }}
              >
                <Image src={payment} width={210} className="paymentimage" />
              </motion.div>
              <Image
                className=" all-image flowimage"
                src={flowimage}
                width={320}
              />
            </div>
          </motion.div>
        </Col>
      </Row>
    </Container>
  );
}

export default AboutAdept;
