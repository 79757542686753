import React, { useState } from "react";
import {
  Container,
  Image,
  Button,
  Row,
  Col,
  Offcanvas,
  NavDropdown,
} from "react-bootstrap";
import { Fade as Hamburger } from "hamburger-react";
import { easeOut, motion } from "framer-motion";
import { NavLink, Link } from "react-router-dom";
import { ExternalLink } from "react-external-link";
import { Nav, Navbar } from "react-bootstrap";

import logo from "../../../assets/logo.svg";

import "../Navbar/navbar.css";

import SelectCountry from "./SelectCountry";

function StickyNavbar() {
  const [loginShow, setLoginShow] = useState(false); // Separate state for login Offcanvas
  const [registerShow, setRegisterShow] = useState(false); // Separate state for register Offcanvas

  const handleLoginClose = () => setLoginShow(false);
  const handleLoginShow = () => setLoginShow(true);

  const handleRegisterClose = () => setRegisterShow(false);
  const handleRegisterShow = () => setRegisterShow(true);

 
  const [animationKey, setAnimationKey] = useState(0);

  const handleDropdownClick = () => {
    // Increment the animationKey to trigger a re-render and reset the animation
    setAnimationKey((prevKey) => prevKey + 1);
  };

  

  const [expanded, setExpanded] = useState(false);
  const Harmburgerstyle = {
    color: "#F14810",
    outline: "none",
    border: 0,
  };

  const dropdownNavlink = {
    textDecoration: "none",
    fontSize: 0.8 + "rem",
    marginRight: 0.3 + "rem",
  };

  const navitemcontainer = {
    paddingTop: 0.4 + "rem",
    paddingBottom: 0.4 + "rem",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  };

  return (
    <Navbar
      collapseOnSelect
      sticky="top"
      expand="lg"
      expanded={expanded}
      className="sticky-navbar"
    >
      <Container fluid>
        <Navbar.Brand href="#home">
          <Link to="/">
            <Image className="navlogo" src={logo} fluid alt="image" />
          </Link>
        </Navbar.Brand>

        <Navbar.Toggle
          className="hamburger"
          aria-controls="basic-navbar-nav"
          style={Harmburgerstyle}
          onClick={() => setExpanded(!expanded)}
        >
          <Hamburger toggled={expanded} duration={0.8} hideOutline={false} />
        </Navbar.Toggle>
        <Navbar.Collapse id="responsive-navbar-nav">
          <Nav className="me-auto"></Nav>
          <Nav>
           
            <NavDropdown title="Our Services" id="basic-nav-dropdown"  onClick={handleDropdownClick}>
              <motion.div
                key={animationKey} 
                initial={{ opacity: 0, y: -10 }}
                animate={{ opacity: 1, y: 0 }}
                exit={{ opacity: 0, y: -10 }}
                transition={{ duration: 0.3 }}
              >
                <div>
                  <NavDropdown.Item href="#action/3.1">
                    <div style={navitemcontainer}>
                      <span style={dropdownNavlink} to="/">
                        Adept for Education (A4E)
                      </span>
                      <svg
                        style={{ marginTop: 0.4 + "rem" }}
                        xmlns="http://www.w3.org/2000/svg"
                        width="16"
                        height="12"
                        viewBox="0 0 18 13"
                        fill="none"
                      >
                        <path
                          fill-rule="evenodd"
                          clip-rule="evenodd"
                          d="M17.757 6.5L11.9043 0.645996L10.9524 1.59657L15.1836 5.82774L-8.3421e-06 5.82774V7.17226L15.1836 7.17226L10.9524 11.4021L11.9043 12.354L17.757 6.5Z"
                          fill="#515151"
                        />
                      </svg>
                    </div>
                  </NavDropdown.Item>

                  <NavDropdown.Item href="#action/3.1">
                    <div style={navitemcontainer}>
                      <span style={dropdownNavlink} to="/">
                        Adept for Utilities (A4U)
                      </span>
                      <svg
                        style={{ marginTop: 0.4 + "rem" }}
                        xmlns="http://www.w3.org/2000/svg"
                        width="16"
                        height="12"
                        viewBox="0 0 18 13"
                        fill="none"
                      >
                        <path
                          fill-rule="evenodd"
                          clip-rule="evenodd"
                          d="M17.757 6.5L11.9043 0.645996L10.9524 1.59657L15.1836 5.82774L-8.3421e-06 5.82774V7.17226L15.1836 7.17226L10.9524 11.4021L11.9043 12.354L17.757 6.5Z"
                          fill="#515151"
                        />
                      </svg>
                    </div>
                  </NavDropdown.Item>

                  <NavDropdown.Item href="#action/3.1">
                    <div style={navitemcontainer}>
                      <span style={dropdownNavlink} to="/">
                        Adept for Commerce (A4C)
                      </span>
                      <svg
                        style={{ marginTop: 0.4 + "rem" }}
                        xmlns="http://www.w3.org/2000/svg"
                        width="16"
                        height="12"
                        viewBox="0 0 18 13"
                        fill="none"
                      >
                        <path
                          fill-rule="evenodd"
                          clip-rule="evenodd"
                          d="M17.757 6.5L11.9043 0.645996L10.9524 1.59657L15.1836 5.82774L-8.3421e-06 5.82774V7.17226L15.1836 7.17226L10.9524 11.4021L11.9043 12.354L17.757 6.5Z"
                          fill="#515151"
                        />
                      </svg>
                    </div>
                  </NavDropdown.Item>
                </div>
              </motion.div>
            </NavDropdown>

            <NavLink
              className="nav-link"
              style={{ textDecoration: "none" }}
              to="/ghanacontactus"
            >
              Contact Us
            </NavLink>

            {"   "}

            <div className="buttonbody">
              <Button
                variant="outline-primary"
                onClick={handleLoginShow}
                style={{
                  borderColor: "#F14810",
                  color: "#F14810",
                  borderRadius: 0.3 + "rem",
                }}
                className="button"
                size="md"
              >
                <span
                  style={{
                    fontSize: 0.73 + "rem",
                    marginLeft: 0.5 + "rem",
                    marginRight: 0.5 + "rem",
                  }}
                >
                  LOGIN
                </span>
              </Button>{" "}
              <Button
                variant="primary"
                onClick={handleRegisterShow}
                style={{
                  backgroundColor: "#F14810",
                  borderColor: "#F14810",
                  borderRadius: 0.3 + "rem",
                }}
                className="button"
                size="md"
              >
                <span
                  style={{
                    fontSize: 0.73 + "rem",
                    marginLeft: 0.3 + "rem",
                    marginRight: 0.3 + "rem",
                  }}
                >
                  CREATE AN ACCOUNT
                </span>
              </Button>
            </div>
        
              <SelectCountry />
        

       

            <Offcanvas
              className="offcanvas"
              placement="end"
              show={loginShow}
              onHide={handleLoginClose}
            >
              <Offcanvas.Header closeButton>
                <Offcanvas.Title>
                  <h6
                    style={{
                      color: "white",
                      fontSize: 1.8 + "rem",
                      fontFamily: "jost",
                    }}
                  >
                    Login
                  </h6>
                </Offcanvas.Title>
              </Offcanvas.Header>
              <Offcanvas.Body>
                <motion.div
                  initial={{ y: 20, opacity: 0 }}
                  whileInView={{ y: -5, opacity: 1 }}
                  transition={{
                    duration: 0.3,
                    delay: 0.2,
                    timingFunction: easeOut,
                  }}
                  viewport={{ once: true }}
                >
                  <div className="cardLink">
                    <ExternalLink
                      style={{ textDecoration: "none" }}
                      href="https://education.adept.ng/login"
                    >
                      <Row>
                        <Col xs={10} md={10} lg={10}>
                          <h6 style={{ color: "#ffffff" }}>
                            Adept for Education (A4E)
                          </h6>
                        </Col>
                        <Col xs={2} md={2} lg={2}>
                          {/* <Image className="arrow" src={arrow} /> */}
                        </Col>
                      </Row>
                    </ExternalLink>
                  </div>
                </motion.div>
                <br />
                <motion.div
                  initial={{ y: 20, opacity: 0 }}
                  whileInView={{ y: -5, opacity: 1 }}
                  transition={{
                    duration: 0.3,
                    delay: 0.4,
                    timingFunction: easeOut,
                  }}
                  viewport={{ once: true }}
                >
                  <div className="cardLink">
                    <Row>
                      <Col xs={10} md={10} lg={10}>
                        <h6 style={{ color: "#ffffff" }}>
                          Adept for Utilities (A4U)
                        </h6>
                      </Col>
                      <Col xs={2} md={2} lg={2}>
                        {/* <Image className="arrow" src={arrow} /> */}
                      </Col>
                    </Row>
                  </div>
                </motion.div>
                <br />
                <motion.div
                  initial={{ y: 20, opacity: 0 }}
                  whileInView={{ y: -5, opacity: 1 }}
                  transition={{
                    duration: 0.3,
                    delay: 0.6,
                    timingFunction: easeOut,
                  }}
                  viewport={{ once: true }}
                >
                  <div className="cardLink">
                    <Row>
                      <Col xs={10} md={10} lg={10}>
                        <h6 style={{ color: "#ffffff" }}>
                          Adept for Commerce (A4C)
                        </h6>
                      </Col>
                      <Col xs={2} md={2} lg={2}>
                        {/* <Image className="arrow" src={arrow} /> */}
                      </Col>
                    </Row>
                  </div>
                </motion.div>
              </Offcanvas.Body>
            </Offcanvas>

            <Offcanvas
              className="offcanvas"
              placement="end"
              show={registerShow}
              onHide={handleRegisterClose}
            >
              <Offcanvas.Header closeButton>
                <Offcanvas.Title>
                  <h6
                    style={{
                      color: "white",
                      fontSize: 1.8 + "rem",
                      fontFamily: "jost",
                    }}
                  >
                    Create an Account
                  </h6>
                </Offcanvas.Title>
              </Offcanvas.Header>
              <Offcanvas.Body>
                <motion.div
                  initial={{ y: 20, opacity: 0 }}
                  whileInView={{ y: -5, opacity: 1 }}
                  transition={{
                    duration: 0.3,
                    delay: 0.2,
                    timingFunction: easeOut,
                  }}
                  viewport={{ once: true }}
                >
                  <div className="cardLink">
                    <ExternalLink
                      style={{ textDecoration: "none" }}
                      href="https://education.adept.ng/register"
                    >
                      <Row>
                        <Col xs={10} md={10} lg={10}>
                          <h6 style={{ color: "#ffffff" }}>
                            Adept for Education (A4E)
                          </h6>
                        </Col>
                        <Col xs={2} md={2} lg={2}>
                          {/* <Image className="arrow" src={arrow} /> */}
                        </Col>
                      </Row>
                    </ExternalLink>
                  </div>
                </motion.div>
                <br />
                <motion.div
                  initial={{ y: 20, opacity: 0 }}
                  whileInView={{ y: -5, opacity: 1 }}
                  transition={{
                    duration: 0.3,
                    delay: 0.4,
                    timingFunction: easeOut,
                  }}
                  viewport={{ once: true }}
                >
                  <div className="cardLink">
                    <Row>
                      <Col xs={10} md={10} lg={10}>
                        <h6 style={{ color: "#ffffff" }}>
                          Adept for Utilities (A4U)
                        </h6>
                      </Col>
                      <Col xs={2} md={2} lg={2}>
                        {/* <Image className="arrow" src={arrow} /> */}
                      </Col>
                    </Row>
                  </div>
                </motion.div>
                <br />
                <motion.div
                  initial={{ y: 20, opacity: 0 }}
                  whileInView={{ y: -5, opacity: 1 }}
                  transition={{
                    duration: 0.3,
                    delay: 0.6,
                    timingFunction: easeOut,
                  }}
                  viewport={{ once: true }}
                >
                  <div className="cardLink">
                    <Row>
                      <Col xs={10} md={10} lg={10}>
                        <h6 style={{ color: "#ffffff" }}>
                          Adept for Commerce (A4C)
                        </h6>
                      </Col>
                      <Col xs={2} md={2} lg={2}>
                        {/* <Image className="arrow" src={arrow} /> */}
                      </Col>
                    </Row>
                  </div>
                </motion.div>
              </Offcanvas.Body>
            </Offcanvas>
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
}

export default StickyNavbar;
