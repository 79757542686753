import React from "react";
import { Container, Image, Row, Col } from "react-bootstrap";
 import { Link } from "react-router-dom";
import { easeOut, motion } from "framer-motion";
import schoolicons from "../../../../assets/schoolicons.svg";
import utilityicons from "../../../../assets/utilityicons.svg";
import commerceicon from "../../../../assets/commerceicon.svg";


import "../../homepage/homepage.css";

function Services() {
  return (
    <Container fluid className="services">
      <motion.div
        initial={{ opacity: 0, y: 40 }}
        whileInView={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.3, delay: 0.4, timingFunction: easeOut }}
        viewport={{ once: true }}
      >
        <center>
          <h3 className="howitworkhead">Our Services</h3>
          <p className="howitworkparagraph">
          {/* Adept is a customizable solution that can be tailored around 
          any organizations payment collection needs.<br/> The top 3 use cases, 
          however, are; */}
            We extend our expertise to provide customizable solutions tailored to various business needs.
            <br/>Explore our diverse services that redefine efficiency and convenience.
          </p>
        </center>
      </motion.div>

      <Row className="servicesrow">
        <Col xs={12} md={3} lg={3}>
          <motion.div
            initial={{ opacity: 0, y: 40 }}
            whileInView={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.3, delay: 0.4, timingFunction: easeOut }}
            viewport={{ once: true }}
          >
            <div className="howitworkcardbody">
              <Image src={schoolicons} fluid />

              <div style={{ marginTop: 1.2 + "rem" }}>
                <h6><strong>Adept for Education (A4E)</strong></h6>
                <p className="howitworkeachparagraph">
                  An AI-powered comprehensive payment solution for educational institutions by assigning a unique account number to every student for fees, levies and other school related payments.
                  This helps to eliminate manual fees collection processes and ensures efficient tracking of student payments.
                </p>
                <Link to="https://education.adept.ng">
                <span style={{color:'#F14810', fontSize:0.8+'rem'}}>Learn More</span>
                </Link>
              </div>
            </div>
          </motion.div>
        </Col>
        <Col xs={12} md={3} lg={3}>
          <motion.div
            initial={{ opacity: 0, y: 40 }}
            whileInView={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.3, delay: 0.6, timingFunction: easeOut }}
            viewport={{ once: true }}
          >
            <div className="howitworkcardbody">
              <Image src={utilityicons} fluid />

              <div style={{ marginTop: 1.2 + "rem" }}>
              <h6><strong>Adept for Utilities (A4U)</strong></h6>
                <p className="howitworkeachparagraph">
                  Adept for Utility allows service providers to assign account numbers to their customers to simplify payments,
                  receiving instant value and automated receipts.
                  <br />
                  <br />
                  <br />
                  <br />
                  <br />
                </p>
                <Link to="/ourservice#utility">
                <span style={{color:'#F14810', fontSize:0.8+'rem'}}>Learn More</span>
                </Link>
              </div>
            </div>
          </motion.div>
        </Col>
        <Col xs={12} md={3} lg={3}>
          <motion.div
            initial={{ opacity: 0, y: 40 }}
            whileInView={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.3, delay: 0.8+0.2, timingFunction: easeOut }}
            viewport={{ once: true }}
          >
            <div className="howitworkcardbody">
              <Image src={commerceicon} fluid />

              <div style={{ marginTop: 1.2 + "rem" }}>
              <h6><strong>Adept for Commerce (A4C)</strong></h6>
                <p className="howitworkeachparagraph">
                  Adept for Commerce empowers businesses by allowing them to assign account numbers to their clients and distributors to simplify recurring payments,
                  providing a seamless experience for both businesses and their partners.
                  <br />
                  <br />
                  <br />
                </p>
                <Link to="/ourservice#utility">
                <span style={{color:'#F14810', fontSize:0.8+'rem'}}>Learn More</span>
                </Link>
              </div>
            </div>
          </motion.div>
        </Col>
        <Col xs={12} md={3} lg={3}>
          <motion.div
            initial={{ opacity: 0, y: 40 }}
            whileInView={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.3, delay: 0.8 + 0.2, timingFunction: easeOut }}
            viewport={{ once: true }}
          >
            <div className="howitworkcardbody">
              <Image src={commerceicon} fluid />

              <div style={{ marginTop: 1.2 + "rem" }}>
                <h6><strong>Adept White-label API</strong></h6>
                <p className="howitworkeachparagraph">
                  Our API solution allows customizable payment structures and is tailored to suit your diverse business needs.
                  Now you have the power to determine how Adept works for you.
                  <br />
                  <br />
                  <br />
                  <br />
                  <br />
                </p>
                <Link to="/ourservice#utility">
                  <span style={{ color: '#F14810', fontSize: 0.8 + 'rem' }}>Learn More</span>
                </Link>
              </div>
            </div>
          </motion.div>
        </Col>
      </Row>
    </Container>
  );
}

export default Services;
